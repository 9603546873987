//@import "bootstrap/bootstrap";
//@import "../bower/bootstrap/less/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

.jqtree-element {
	min-height: 30px;
	/*padding: 3px;*/
	padding: 3px 0;
}

table.dataTable tbody tr.selected {
	background-color: #97BDD6 !important;
   background: -webkit-gradient(linear, left top, left bottom, from(#BEE0F5), to(#89AFCA)) !important;
   background: -moz-linear-gradient(top, #BEE0F5, #89AFCA) !important;
   background: -ms-linear-gradient(top, #BEE0F5, #89AFCA) !important;
   background: -o-linear-gradient(top, #BEE0F5, #89AFCA) !important;
   text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7) !important;
}
